<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-vertical is-half"
      v-if="entity">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Rates</p>
          <div v-if="isNtar"
            class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Shop Rate</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="entity.shopRate"
                      :min="0"
                      placeholder="Shop Rate"
                      :precision="2"
                      v-focus-inserted />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isNtar"
            class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Paint Cons.</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="entity.paintCons"
                      :min="0"
                      placeholder="Paint Cons."
                      :precision="2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isNtar"
            class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Panel Cons.</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="entity.panelCons"
                      :min="0"
                      placeholder="Panel Cons."
                      :precision="2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isNtar"
            class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Panel Cons. Min</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="entity.panelConsMin"
                      :min="0"
                      placeholder="Panel Cons. Min"
                      :precision="2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isNtar">
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field-label is-normal">
                  <label class="label">{{ getLabourColumnName(labourCodeEnum.RR) }}</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <vue-numeric class="input has-text-right"
                        v-model="getLabourRate(labourCodeEnum.RR).rate"
                        :min="0"
                        :placeholder="getLabourColumnName(labourCodeEnum.RR)"
                        :precision="2"
                        v-focus-inserted />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field-label is-normal">
                  <label class="label">{{ getLabourColumnName(labourCodeEnum.REP) }}</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <vue-numeric class="input has-text-right"
                        v-model="getLabourRate(labourCodeEnum.REP).rate"
                        :min="0"
                        :placeholder="getLabourColumnName(labourCodeEnum.REP)"
                        :precision="2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field-label is-normal">
                  <label class="label">{{ getLabourColumnName(labourCodeEnum.RWA) }}</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <vue-numeric class="input has-text-right"
                        v-model="getLabourRate(labourCodeEnum.RWA).rate"
                        :min="0"
                        :placeholder="getLabourColumnName(labourCodeEnum.RWA)"
                        :precision="2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field-label is-normal">
                  <label class="label">{{ (labourCodeEnum.MECH) }}</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <vue-numeric class="input has-text-right"
                        v-model="getLabourRate(labourCodeEnum.MECH).rate"
                        :min="0"
                        :placeholder="getLabourColumnName(labourCodeEnum.MECH)"
                        :precision="2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field-label is-normal">
                  <label class="label">{{ getLabourColumnName(labourCodeEnum.CD) }}</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <vue-numeric class="input has-text-right"
                        v-model="getLabourRate(labourCodeEnum.CD).rate"
                        :min="0"
                        :placeholder="getLabourColumnName(labourCodeEnum.CD)"
                        :precision="2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field-label is-normal">
                  <label class="label">{{ getLabourColumnName(labourCodeEnum.CRUSH) }}</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <vue-numeric class="input has-text-right"
                        v-model="getLabourRate(labourCodeEnum.CRUSH).rate"
                        :min="0"
                        :placeholder="getLabourColumnName(labourCodeEnum.CRUSH)"
                        :precision="2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field-label is-normal">
                  <label class="label">{{ getLabourColumnName(labourCodeEnum.FIBER) }}</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <vue-numeric class="input has-text-right"
                        v-model="getLabourRate(labourCodeEnum.FIBER).rate"
                        :min="0"
                        :placeholder="getLabourColumnName(labourCodeEnum.FIBER)"
                        :precision="2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isRacq"
              class="field is-horizontal">
              <div class="field-body">
                <div class="field-label">
                  <label class="label">RACQ Cons.</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <vue-numeric class="input has-text-right"
                        v-model="entity.paintCons"
                        :min="0"
                        placeholder="RACQ Cons."
                        :precision="2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div v-if="!isNtar"
        class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Paint Rates</p>
          <div class="columns">
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-body">
                  <div class="field-label is-normal">
                    <label class="label">{{ getLabourColumnName(labourCodeEnum.S1) }}</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <vue-numeric class="input has-text-right"
                          v-model="getLabourRate(labourCodeEnum.S1).rate"
                          :min="0"
                          :placeholder="getLabourColumnName(labourCodeEnum.S1)"
                          :precision="2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-body">
                  <div class="field-label is-normal">
                    <label class="label">{{ getLabourColumnName(labourCodeEnum.S2) }}</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <vue-numeric class="input has-text-right"
                          v-model="getLabourRate(labourCodeEnum.S2).rate"
                          :min="0"
                          :placeholder="getLabourColumnName(labourCodeEnum.S2)"
                          :precision="2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-body">
                  <div class="field-label is-normal">
                    <label class="label">{{ getLabourColumnName(labourCodeEnum.S3) }}</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <vue-numeric class="input has-text-right"
                          v-model="getLabourRate(labourCodeEnum.S3).rate"
                          :min="0"
                          :placeholder="getLabourColumnName(labourCodeEnum.S3)"
                          :precision="2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-body">
                  <div class="field-label is-normal">
                    <label class="label">{{ getLabourColumnName(labourCodeEnum.M1) }}</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <vue-numeric class="input has-text-right"
                          v-model="getLabourRate(labourCodeEnum.M1).rate"
                          :min="0"
                          :placeholder="getLabourColumnName(labourCodeEnum.M1)"
                          :precision="2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-body">
                  <div class="field-label is-normal">
                    <label class="label">{{ getLabourColumnName(labourCodeEnum.M2) }}</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <vue-numeric class="input has-text-right"
                          v-model="getLabourRate(labourCodeEnum.M2).rate"
                          :min="0"
                          :placeholder="getLabourColumnName(labourCodeEnum.M2)"
                          :precision="2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-body">
                  <div class="field-label is-normal">
                    <label class="label">{{ getLabourColumnName(labourCodeEnum.M3) }}</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <vue-numeric class="input has-text-right"
                          v-model="getLabourRate(labourCodeEnum.M3).rate"
                          :min="0"
                          :placeholder="getLabourColumnName(labourCodeEnum.M3)"
                          :precision="2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Markups</p>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">New</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="entity.newMarkup"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="New Markup %"
                      symbol-position="suffix"
                      :precision="2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Used</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="entity.usedMarkup"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="Used Markup %"
                      symbol-position="suffix"
                      :precision="2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Recore</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="entity.recoreMarkup"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="Recore %"
                      symbol-position="suffix"
                      :precision="2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Aftermarket</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="entity.afterMarketMarkup"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="Aftermarket %"
                      symbol-position="suffix"
                      :precision="2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Exchange</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="entity.exchangeMarkup"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="Exchange %"
                      symbol-position="suffix"
                      :precision="2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Reconditioned</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="entity.reconditionedMarkup"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="Reconditioned %"
                      symbol-position="suffix"
                      :precision="2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { LabourTimeTypes, LabourItemCodeTypes } from '@/enums'
import VueNumeric from '@/components/VueNumeric'
import { FocusInserted } from '@/components/directives'

export default {
  name: 'InsurerRatesMarkups',
  components: {
    VueNumeric
  },
  directives: {
    FocusInserted
  },
  props: {
    entity: null
  },
  data() {
    return {}
  },
  computed: {
    labourCodeEnum: function() {
      return LabourItemCodeTypes
    },
    isNtar() {
      return (
        this.entity &&
        (this.entity.labourType === LabourTimeTypes.NTAR || this.entity.labourType === LabourTimeTypes.LTAR || this.entity.labourType === LabourTimeTypes.eMTA)
      )
    },
    isRacq() {
      return this.entity && this.entity.labourType === LabourTimeTypes.RACQ
    }
  },
  watch: {
    'entity.shopRate': function(newVal, oldVal) {
      if (this.isNtar) {
        this.entity.insurerLabourRates.forEach(r => r.rate = newVal)
      }
    }
  },
  methods: {
    getLabourRate(code) {
      if (!this.entity.insurerLabourRates) {
        return {}
      }
      var rate = this.entity.insurerLabourRates.find(i => i.labourCode === code)
      if (!rate) {
        const newRate = {
          columnName: code,
          insurerId: this.entity.insurerId,
          labourCode: code,
          labourDesc: code,
          labourType: code,
          rate: 0,
          modifiedBy: '',
          modifiedDate: null,
          createdBy: '',
          createdDate: null,
          isNew: true,
          isDeleted: false
        }
        this.entity.insurerLabourRates.push(newRate)
        rate = newRate
      }
      return rate
    },
    getLabourColumnName(code) {
      if (!this.entity.insurerLabourRates) {
        return ''
      }

      var rate = this.entity.insurerLabourRates.find(i => i.labourCode === code)
      return rate ? rate.columnName : code
    }
  }
}
</script>

<style lang="scss">
</style>
